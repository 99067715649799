import { DEV_URL, APIKEY } from "../constants/config";

const request = async (url, method, payload) => {
    const options = {
        method: method ? method : 'GET',
        headers: {
            "Content-Type": "application/json"
        },
    };

    options.headers['api-key'] = APIKEY;
    if (payload) {
        options.body = JSON.stringify(payload);
    }

    const apiUrl = `${DEV_URL}/${url}`;
    
    try {
        //dispatch({ type: IS_LOADING, payload: true });
        let res = await fetch(
            apiUrl,
            options
        );
        let data = url.includes('poi/get-image') ? res :await res.json();
        if (data.status === "ok") {
            //dispatch({ type: IS_LOADING, payload: false });
        } else {
            //dispatch({ type: IS_LOADING, payload: false });
            console.log(res.error);
        }
        return data;
    } catch (error) {
        console.log(error);
    }
};

export { request };