import { Link } from "react-router-dom";
import Heading from "../commons/heading";

const Wanderlust = (props) => {
    return (
        <>
            <div className="container mx-auto px-[20px] pt-[120px]">
                <Heading className={'text-center'}>
                    Experiencing Wanderlust?
                </Heading>
                <p className={'text-[1.75rem] lg:text-4xl my-6 font-bold font-mulish leading-[1.2em] text-center'}>
                    Get trip inspiration today
                </p>
                <Link to={`my-recommendations`} className="hover:bg-theme--lightBlue hover:text-black block w-fit font-mulish mx-auto rounded-full text-white text-[24px] bg-theme--blue font-bold py-2 px-6">Start planning your trip!</Link>
            </div>
        </>
    );
}

export default Wanderlust;