import { Link } from "react-router-dom";

const Logo = (props) => {
    return (
        <>
            <Link to={'/'}>
                <img width={110} className={`${props.className}`} src={'assets/images/logo.svg'} alt="logo" />
            </Link>
        </>
    );
}

export default Logo;