import React, { useState, useEffect } from "react";
import { Drawer } from "@mui/material";
import { Link } from "react-router-dom";

import Logo from "./logo";
import { useAuth, SignInButton, SignUpButton, SignOutButton, useUser } from '@clerk/clerk-react';

const Menu = (props) => {

    const { user } = useUser();

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const currentPath = window.location.pathname;

    const [open, setOpen] = useState(false);
    const toggleDrawer = (openStatus) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setOpen(openStatus);
    };
    const { getToken, userId, isSignedIn } = useAuth();
    useEffect(() => {
        setIsLoggedIn(isSignedIn);
    }, [isSignedIn]);

    const openSignIn = () => {
        setOpen(false)
        document.getElementById('clerk--SignInButton').click();
    }
    const openSignUp = () => {
        setOpen(false)
        document.getElementById('clerk--SignUpButton').click();
    }

    return (
        <>
            <Drawer open={open} onClose={toggleDrawer(false)}>
                <div className="p-3 mt-10">
                    <Logo className={`mb-3`} />
                    <ul>
                        <li className="mb-2">
                            <Link className={`text-[14px] leading-[1.1em] ${currentPath === '/my-recommendations' ? 'font-semibold underline' : ''}`} to={'my-recommendations'}>My Recommendations</Link>
                        </li>
                        <li className="mb-2">
                            {/* <Link className={`text-[14px] leading-[1.1em] ${currentPath === '/travel-guides' ? 'font-semibold underline' : ''}`} to={'travel-guides'}>Travel Guides</Link> */}
                            <Link className={`text-[14px] leading-[1.1em] ${currentPath === '/saved-searches' ? 'font-semibold underline' : ''}`} to={'saved-searches'}>Saved & Liked</Link>
                        </li>
                        <li className="mb-2">
                            <Link className={`text-[14px] leading-[1.1em] ${currentPath === '/blog' ? 'font-semibold underline' : ''}`} to={'https://blog.mytaba.com/'}>Blog</Link>
                        </li>
                        <li className="mb-2">
                            {/* <Link className={`text-[14px] leading-[1.1em] ${currentPath === '/about-us' ? 'font-semibold underline' : ''}`} to={'about-us'}>About Us</Link> */}
                            <Link className={`text-[14px] leading-[1.1em] ${currentPath === '/contact-us' ? 'font-semibold underline' : ''}`} to={'https://blog.mytaba.com/contact'}>Contact Us</Link>
                        </li>
                        {
                            !isLoggedIn && <>
                                <button onClick={() => openSignIn()} className="hover:bg-theme--lightBlue hover:text-black rounded-full text-white text-[12px] bg-theme--blue font-bold py-2 px-3 me-2">Sign In</button>
                                <SignInButton id="clerk--SignInButton" className="hidden" mode="modal" />

                                <button onClick={() => openSignUp()} className="hover:bg-theme--lightBlue hover:text-black rounded-full text-white text-[12px] bg-theme--grey font-bold py-2 px-3 me-2">Sign Up</button>
                                <SignUpButton id="clerk--SignUpButton" className="hidden" mode="modal" />
                            </>
                        }
                        {
                            isLoggedIn && <>
                                <li className="mb-2">
                                    <Link className={`text-[14px] leading-[1.1em] ${currentPath === '/profile' ? 'font-semibold underline' : ''}`} to={'profile'}>Profile</Link>
                                </li>
                                <SignOutButton className="hover:bg-theme--lightBlue hover:text-black rounded-full text-white text-[12px] bg-theme--grey font-bold py-2 px-3 me-2" mode="modal" />
                            </>
                        }
                    </ul>
                </div>
            </Drawer>
            <div className="hidden lg:block menubar">
                <Link className={`me-3 ${currentPath === '/my-recommendations' ? 'font-semibold underline' : ''}`} to={'my-recommendations'}>My Recommendations</Link>
                <Link className={`me-3 ${currentPath === '/saved-searches' ? 'font-semibold underline' : ''}`} to={'saved-searches'}>Saved & Liked</Link>
                {/* <Link className={`me-3 ${currentPath === '/travel-guides' ? 'font-semibold underline' : ''}`} to={'travel-guides'}>Travel Guides</Link> */}
                <Link className={`me-3 ${currentPath === '/blog' ? 'font-semibold underline' : ''}`} to={'https://blog.mytaba.com/'}>Blog</Link>
                <Link className={`${currentPath === '/contact-us' ? 'font-semibold underline' : ''}`} to={'https://blog.mytaba.com/contact'}>Contact Us</Link>
                {/* <Link className={`${currentPath === '/about-us' ? 'font-semibold underline' : ''}`} to={'about-us'}>About Us</Link> */}
            </div>
            <div className="flex items-center justify-center border border border-black rounded-full lg:hidden">
                {
                    isLoggedIn && <>
                        <button onClick={toggleDrawer(true)}>
                            <img className="rounded-full max-w-[40px]" src={user?.imageUrl} alt={'avatar'} />
                        </button>
                    </>
                }
                  {
                    !isLoggedIn && <>
                <button className="p-2" onClick={toggleDrawer(true)}>
                    <img src={'assets/images/icons/hamburger.png'} alt="mobile menu" />
                </button>
                </>
                }
            </div>
        </>
    );
}

export default Menu;