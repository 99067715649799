import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers/index";
import { composeWithDevTools } from '@redux-devtools/extension';
import { configureStore } from "@reduxjs/toolkit";

const middleware = [thunk];
const initialState = {};
const store = configureStore({
    reducer: rootReducer,
});

export default store;