import {useEffect, useState} from "react";
import ReactStars from "react-stars";
import Map from "./map";
import {request} from '../../services/http-service';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {SignInButton, useAuth, useUser} from '@clerk/clerk-react';
import LikeDislike from "./like-dislike";
import "./recommendations.css";
import {APIKEY, DEV_URL} from "../../constants/config";


const MyRecommendations = (props) => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const { isSignedIn } = useAuth();
    const { user } = useUser();

    useEffect(() => {
        setIsLoggedIn(isSignedIn);
        if (user && user.id && isSignedIn) {
            const url = `api/v1/session/claim-session`;
            let body = {
                traveler_id: props.travelerId,
                session_id: props.sessionId,
            };
            request(url, "put", body).then((res) => {
            });
        }

    }, [isSignedIn]);


    const [tab, setTab] = useState("recommended");
    const [recommendationData, setRecommendationData] = useState([]);
    const [city, setCity] = useState("");
    const [mustSeeSitesData, setMustSeeSitesData] = useState([]);

    const handleTabChange = (t) => {
        setTab(t)
    }
    useEffect(() => {
        setRecommendationData(props.data);
        setCity(props.data?.length ? `${props.data[0].city} city` : '');
    }, [props.data]);


    useEffect(() => {
        setMustSeeSitesData(props.sitesData);
    }, [props.sitesData]);



    const saveRecommendation = () => {
        if (user && user.id && isSignedIn) {
        const url = `api/v1/session/save-session`;

        let body = {
            save: true,
            session_id: props.sessionId,
        };


        request(url, "put", body).then((res) => {
            showToast('Recommendation Saved Successfully')
        });
    }else{
        document.getElementById('clerk--SignInButton').click();
    }
    }

    const showToast = (mesg) => {

        toast.success(mesg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    }


    const GenerateRow = ({ index, data }) => {

        const [imgUrl, setImgUrl] = useState('');

        useEffect(() => {
            getImgUrl(data);
        },[data]);
        const getImgUrl = (data) => {
            if (data.img_select.length > 0) {
                setImgUrl(`${data.img_prefix}${data.img_select[0]}`);
            } else {
                const url = `${DEV_URL}/api/v1/poi/get-image/${data.google_image_ref}`;

                const options = {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json"
                    },
                };

                options.headers['api-key'] = APIKEY;

                fetch(url, options)
                    .then(responseImage => responseImage.arrayBuffer())
                    .then(buffer => {
                        // Convert the ArrayBuffer to a base64-encoded string
                        const base64String = btoa(new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
                        data['displayImage'] = 'data:image/png;base64,' + base64String;
                        setImgUrl(data.displayImage);
                    })
                    .catch(error => console.error("Error fetching image:", error));
            }
        }
        return (
            <>
                <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                    <img className="w-full img-poi" src={imgUrl} alt={data?.poi_name} />
                    <div>
                        <p className="font-mulish text-md mb-2 font-bold">{index + 1}. {data?.poi_name}</p>
                        <p className={`w-fit px-4 py-1 rounded-full text-sm mb-2 font-mulish ${data?.percent_match > 50 ? 'bg-theme--green' : 'bg-theme--red'}`}>{data?.percent_match}% Match</p>
                        <div className={`text-sm flex flex-wrap items-center mb-2`}>
                            <ReactStars
                                className="me-1"
                                count={5}
                                value={data?.rating}
                                size={20}
                                edit={false}
                                color2={'#F1C644'} />
                            <p>{data?.rating} ratings</p>
                        </div>
                        <p className={`text-sm mb-2`}>{data.poi_descrh}</p>
                        {/* <div className="flex flex-wrap items-center mb-4">
                            {
                                data.tags.map((t, dti) => {
                                    return (
                                        <>
                                            <span key={dti} className={`text-sm me-2 px-3 py-1 bg-theme--blue rounded-full text-white`}>{t}</span>
                                        </>
                                    );
                                })
                            }
                        </div> */}
                        <LikeDislike sessionId={props.sessionId} data={data} />
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div className="w-full max-w-full mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-[35fr,65fr] gap-0">
                    <div className="p-5 h-screen overflow-auto hide--scrollbar">
                        <p className="font-mulish text-4xl font-bold mb-4">{city}</p>
                        <div className="w-[95%] mx-auto flex items-center border-2 border-[#00000050] rounded-full mb-4 overflow-hidden">
                            <button onClick={() => handleTabChange("recommended")} className={`rec--btns-rec transition-all w-[60%] text-center hover:bg-theme--blue hover:text-white text-[18px] font-semibold py-2 px-4 border-[#00000050] border-e-2 ${tab === "recommended" ? "bg-theme--blue text-white" : "text-[#00000050]"}`}>
                                Recommended for you
                            </button>
                            <button onClick={() => handleTabChange("must")} className={`rec--btns-mst transition-all w-[40%] text-center hover:bg-theme--blue hover:text-white text-[18px] font-semibold py-2 px-4 ${tab === "must" ? "bg-theme--blue text-white" : "text-[#00000050]"}`}>
                                Must See Sites
                            </button>
                        </div>
                        <button onClick={saveRecommendation} type="button" className="w-full mb-6 block bg-theme--lightBlue text-black font-mulish rounded-full text-[24px] hover:bg-theme--blue font-bold py-2 px-4">Save your recommendations</button>
                        <button type="button" className="w-full mb-6 block bg-theme--lightBlue text-black font-mulish rounded-full text-[24px] hover:bg-theme--green font-bold py-2 px-4">Unlock 20 More: $1</button>
                        <div className="recommendations--container">
                            {
                                tab && tab === "recommended"
                                    ? (
                                        recommendationData && recommendationData.map((item, index) => {
                                            return <>
                                                <GenerateRow key={index} index={index} data={item} />
                                            </>
                                        })
                                    ) :
                                    (
                                        tab === "must" ?
                                            (
                                                mustSeeSitesData && mustSeeSitesData.map((item, index) => {
                                                    return <>
                                                        <GenerateRow key={index} index={index} data={item} />
                                                    </>
                                                })
                                            )
                                            : null
                                    )
                            }
                        </div>
                    <button type="button" className="w-full mb-6 block bg-theme--lightBlue text-black font-mulish rounded-full text-[24px] hover:bg-theme--green font-bold py-2 px-4">Unlock 20 More: $1</button>
                    </div>
                    <div>
                        <Map data={tab === "recommended" ? recommendationData : mustSeeSitesData} />
                    </div>

                </div>
            </div >
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <ToastContainer />
            <SignInButton id="clerk--SignInButton" className="hidden" mode="modal" />
        </>
    );
}

export default MyRecommendations;
