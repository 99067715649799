import React from "react";
import './App.css';
import './Fonts.css';
import AppRoutes from "./routes";
import { CLERK_KEYS } from "./constants/config";
import { ClerkProvider } from "@clerk/clerk-react";
if (!CLERK_KEYS._PUBLISH_KEY) {
	throw new Error("Missing Publishable Key")
}
const clerkPubKey = CLERK_KEYS._PUBLISH_KEY;

const App = () => {
	return (
		<>
			<ClerkProvider publishableKey={clerkPubKey}>
				<AppRoutes />
			</ClerkProvider>
		</>
	);
};

export default App;
