import { Link } from "react-router-dom";

const Explore = (props) => {

    return (
        <>
            <div className="bg-theme--lightBlue py-[40px]">
                <div className="container-fluid mx-auto flex flex-col lg:flex-row items-center justify-center relative">
                    <img src={'assets/images/explore-img.png'} alt={''} />
                    <div className="max-w-[620px] p-5">
                        <p className="text-4xl font-black font-mulish leading-[1.2em]">Explore Our Travel Guides</p>
                        <p className="text-3xl font-thin my-8">Get inspired to embark on your next journey today with our detailed travel guides that will help you get there and back again.</p>
                        <Link to={'https://blog.mytaba.com/'} className="inline-block font-mulish rounded-full text-white text-[24px] border border-theme--blue bg-theme--blue font-bold py-2 px-3">Get Inspired</Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Explore;