import React, { useState, useEffect } from 'react';
import { request } from '../../services/http-service';
import { APIKEY, DEV_URL } from '../../constants/config';

const SavedSearches = (props) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        if (props.travelerId) {
            const url = `api/v1/poi/get-saved-session-pois/${props.travelerId}`

            request(url, "get").then((res) => {
                const likeArr = [];
                res.body && res.body.length && Array.isArray(res.body) && res.body.map(res => {
                    getImgUrl(res);
                    likeArr.push(res);
                });
                setData(likeArr);

            });
        }
    }, [props.travelerId]);

    const getImgUrl = (data) => {
        if (data.img_select.length > 0) {

            data['displayImage'] = `${data.img_prefix}${data.img_select[0]}`;

        } else {
            fetchImageFromGoogle(data);
        }
    }
    const fetchImageFromGoogle = async (data) => {

        const url = `${DEV_URL}/api/v1/poi/get-image/${data.google_image_ref}`;

        const options = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            },
        };

        options.headers['api-key'] = APIKEY;
        const responseImage = await fetch(url, options);
        const buffer = await responseImage.arrayBuffer();

        // Convert the ArrayBuffer to a base64-encoded string
        const base64String = btoa(new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));

        // Create the data URL
        data['displayImage'] = 'data:image/png;base64,' + base64String;
    };
    const displayImage = (data, index) => {
        return data.displayImage;
    }

    return (
        <>
            <p className="text-[36px] font-bold mb-1">Saved Searches</p>
            <p className="text-[28px] font-thin mb-6">Get inspired, all over again.</p>

            <p className="font-mulish text-[24px] font-bold mb-4">Spain:</p>
            <div className='mb-6'>
                {
                    data && data.map((loc, index) => {
                        return (
                            <>
                                <div key={index} className='w-full flex items-center justify-start flex-col mb-3'>
                                    <img src={displayImage(loc, index)} alt={loc.poi_name} className='w-full h-full max-h-[400px] object-cover mb-2 me-2' />
                                    <div className='w-full'>
                                        <p className="font-mulish text-[20px] font-bold mb-2">{loc.poi_name}</p>
                                        {/* <p className="text-[14px] font-thin">{loc.address}</p> */}
                                    </div>
                                </div>
                            </>
                        );
                    })
                }
            </div>
        </>
    );
}

export default SavedSearches;