import { useState, useEffect } from "react";
import { request } from '../../services/http-service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SignInButton, useAuth, useUser } from '@clerk/clerk-react';

const LikeDislike = (props) => {

    const data = props.data;
    const sessionId = props.sessionId;

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [likedDisliked, setLikedDisliked] = useState(false);

    const { isSignedIn } = useAuth();
    const { user } = useUser();

    useEffect(() => {
        setIsLoggedIn(isSignedIn);
    }, [isSignedIn]);

    const updateLikeAndDislike = (data, like) => {
        if (isLoggedIn) {
            const url = `api/v1/session/like-dislike`;

            let body = {
                poi_id: data.poi_id,
                session_id: sessionId,
                like_sw: false,
                dislike_sw: false
            };

            if (like) {
                body.like_sw = true;
            } else {
                body.dislike_sw = true;
            };
            request(url, "put", body).then((res) => {
                setLikedDisliked(like ? 'like' : 'dislike')
                showToast(`Recommendation ${like ? 'like' : 'dislike'} Successfully`)
            });
        }
        else {
            document.getElementById('clerk--SignInButton').click();
        }
    }

    const showToast = (mesg) => {

        toast.success(mesg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    }


    return (
        <>
            <div className="flex flex-wrap items-center">
                <button onClick={() => updateLikeAndDislike(data, true)} className="me-2">
                    <img src={`assets/images/icons/${likedDisliked === "like" ? "thumb-up-filled.svg" : "thumb-up-unfilled.svg"}`} alt="like" />
                </button>
                <button onClick={() => updateLikeAndDislike(data, false)}>
                    <img src={`assets/images/icons/${likedDisliked === "dislike" ? "thumb-down-filled.svg" : "thumb-down-unfilled.svg"}`} alt="like" />
                </button>
            </div>
        </>
    );
}

export default LikeDislike;