import { Link } from "react-router-dom";
const Banner = (props) => {
    return (
        <>
            <div className="min-h-[500px] p-3 flex items-center bg-theme--lightBlue relative">
                <div className="video-bg absolute w-full h-full top-0 start-0 overflow-hidden opacity-100 z[-1]">
                    <video className="w-full h-full object-cover object-center" muted autoPlay loop style={{ transition: "opacity 1s ease-in -out 0s" }}>
                        <source src={'https://mytaba-images-prod.s3.amazonaws.com/website/Website_Combined.mp4'} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className="container mx-auto relative">
                    <div className="max-w-[500px] p-[20px] bg-white rounded-[48px]">
                        <p className="text-center sm:text-left font-mulish leading-[1.2em] text-[24px] sm:text-[32px] lg:text-[2.6rem] font-black">Plan the trip of your dreams - in minutes.</p>
                        <p className="text-center sm:text-left leading-[1.2em] text-[18px] sm:text-[26px] lg:text-[32px] font-thin mb-4">Instant trip recommendations personalized just for you.</p>
                        <Link className="block w-full hover:bg-theme--lightBlue hover:text-black text-center mb-2 rounded-full text-white text-[16px] sm:text-[24px] bg-theme--blue font-bold py-2 px-3" to={'my-recommendations'}>Find Your Next Adventure!</Link>
                    </div>
                </div>
            </div >
        </>
    );
}

export default Banner;
