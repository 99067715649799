import { useUser } from "@clerk/clerk-react";
const Banner = (props) => {
    const { user } = useUser();
    return (
        <>
            <div className="min-h-[250px] p-3 flex items-center" style={{ background: 'url(assets/images/hero_2.png) no-repeat center center', backgroundSize: "cover" }}>
                <div className="container mx-auto">
                    <div className="w-fit p-[20px] bg-white rounded-full">
                        <p className="leading-[1.2em] text-[26px] lg:text-[36px] font-bold">Welcome back {user?.firstName}!</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Banner;