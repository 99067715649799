import React, { useState } from 'react';

const Profile = (props) => {

    const [inputs, setInputs] = useState({});

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }

    return (
        <>
            <p className="text-[36px] font-bold mb-1">Account</p>
            <p className="text-[28px] font-thin mb-6">Manage your account information</p>
            <form>
                <p className="text-[24px] font-bold mb-1">Profile</p>
                <p className="px-2 py-1 mb-2 block text-[18] font-thin rounded-lg">{props.profile?.username}</p>
            </form>
        </>
    )
}

export default Profile;