import { Navigate, BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import { request } from '../services/http-service';
import { useEffect, useState } from "react";
import AuthProtected from "./AuthProtected";
import PublicLayout from "../components/Layouts/PublicLayout";
import AccountLayout from "../components/Layouts/AccountLayout";
import QuizLayout from "../components/Layouts/QuizLayout";
import RecommendationsLayout from "../components/Layouts/RecommendationsLayout";
import Home from "../components/home/home";
import Profile from "../components/profile/profile";
import SavedSearches from "../components/saved-searches/saved-searches";
import LikedDestinations from "../components/liked-destinations/liked-destinations";
import MyRecommendations from "../components/my-recommendations/my-recommendations";
import Quiz from "../components/quiz/quiz";

const AppRoutes = () => {
	const { user, isSignedIn } = useUser();
	const [travelerId, setTravelerId] = useState("");
	let user2 = {
		name: "Dev",
		role: "admin",
		loggedIn: true,
	};
	useEffect(() => {
       if (user && user.id && isSignedIn) {
		const url = `api/v1/session/check-traveler`
		const data = {
			"clerk_id": user.id,
			"create_user_id": ""
		};

		request(url, "post", data).then((res) => {
			setTravelerId(res?.traveler_id)
		});
	}
    }, [isSignedIn, user]);
	return (
		<>

			<Router>
				<Routes>
					<Route element={<PublicLayout />}>
						<Route path="/" element={<Home />} />
					</Route>
					<Route element={<AuthProtected user={user2} isLoggedIn={true} />}>
						<Route element={<AccountLayout />}>
							<Route path="/profile" element={<Profile profile={user} user={user2} />} />
							<Route path="/saved-searches" element={<SavedSearches travelerId={travelerId} />} />
							<Route path="/liked-destinations"  element={<LikedDestinations travelerId={travelerId} />} />
						</Route>
					</Route>
					<Route element={<QuizLayout />}>
						<Route path="/my-recommendations" element={<Quiz profile={user} />} />
					</Route>

					{/* <Route element={<RecommendationsLayout />}>
						<Route path="/my-recommendations" element={<MyRecommendations />} />
					</Route> */}

					<Route element={<AuthProtected user={user2} />}>
						<Route element={<PublicLayout />}>
						</Route>
					</Route>


				</Routes>
			</Router>

		</>
	);
}

export default AppRoutes;