import React, {useEffect, useState} from 'react';
import Box from "../commons/box";
import {Link} from 'react-router-dom';
import {request} from '../../services/http-service';
import MyRecommendations from "../../components/my-recommendations/my-recommendations";
import {APIKEY, DEV_URL} from '../../constants/config';
import {toast, ToastContainer} from 'react-toastify';
import "./quiz.css";

const Quiz = (props) => {

    const recommendationsRating = [
        {
            key: "Not at all Interested",
            value: 1
        },
        {
            key: "Somewhat Interested",
            value: 2
        },
        {
            key: "Moderately Interested",
            value: 3
        },
        {
            key: "Very Interested",
            value: 4
        },
        {
            key: "Extremely Interested",
            value: 5
        },
    ]

    const locationsArray = [
        {
            country: "Spain",
            value: "esp",
            locations: [
                {
                    image: "https://mytaba-images-prod.s3.amazonaws.com/location/esp/barcelona/bcf22fb5bf5c0c29b8f3d5a75c697306.webq",
                    title: "Barcelona"
                },
                {
                    image: "https://mytaba-images-prod.s3.amazonaws.com/location/esp/cordoba/c0a358474bbb0978eaa251adecf725f9.webq",
                    title: "Cordoba"
                },
                {
                    image: "https://mytaba-images-prod.s3.amazonaws.com/location/esp/granada/f9caf567c7b829f3538f9398765d04eb.webq",
                    title: "Granada"
                },
                {
                    image: "https://mytaba-images-prod.s3.amazonaws.com/location/esp/madrid/8666c751ca84d325acf6bbcf90074012.webq",
                    title: "Madrid"
                },
                {
                    image: "https://mytaba-images-prod.s3.amazonaws.com/location/esp/segovia/e86b015ad9faf6e07e5c9541130be470.webq",
                    title: "Segovia"
                },
                {
                    image: "https://mytaba-images-prod.s3.amazonaws.com/location/esp/seville/a53c17b73bf22683c67eed5e69919084.webq",
                    title: "Seville"
                },
            ]
        }
    ];

     // TEST REMOVAL TO SEE IF REFRESH BUG PERSEVERES
    const scrollToTop = () => {
        if (browserType !== "Firefox") {
            //document.body.scrollIntoView({behavior: 'smooth'});
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        } else {
            window.scrollTo(0, 0);
        }
    }

    
    const [step, setStep] = useState(1);
    const [img, setImg] = useState(null);
    const handleChangeStep = (s) => {
        setStep(s)
        scrollToTop();
    }
    const [browserType, setBrowserType] = useState("");

    const [sessionId, setSessionId] = useState("");
    const [travelerId, setTravelerId] = useState(null);
    const [showRecommendation, setShowRecommendation] = useState(false);
    const [step1, setStep1] = useState("");
    const [step2, setStep2] = useState([]);
    const [step2Text, setStep2Text] = useState("");
    const [step3, setStep3] = useState({});
    const [step3Destinations, setStep3Destinations] = useState([]);
    const [step3Data, setStep3Data] = useState([]);
    const [loopCount, setLoopCount] = useState(1);

    const [tripTypes, setTripTypesArr] = useState({});
    const [locationsArr, setLocationsArr] = useState(locationsArray);
    const [location, setLocation] = useState(null);
    const [rating, setRating] = useState(null);
    const [recommendationData, setRecommendationData] = useState([]);
    const [mustSeeSitesData, setMustSeeSitesData] = useState([]);
    const handleLocSelect = (e, location, country, country_code) => {
        e.preventDefault();
        let setCountryObject = location;
        setCountryObject.country_code = country_code;
        setLocation(setCountryObject)
        setStep1(`${location.title}, ${country}`)
    };

    // execute once to determine the browser type
    useEffect(() => {
        let userAgent = navigator.userAgent;
        if (userAgent.indexOf("Firefox") > -1) {
            setBrowserType("Firefox");
        } else if (userAgent.indexOf("Chrome") > -1) {
            setBrowserType("Chrome");
        } else if (userAgent.indexOf("Safari") > -1) {
            setBrowserType("Safari");
        } else if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("rv:") > -1) {
            setBrowserType("IE");
        } else {
            setBrowserType("Unknown");
        }
    }, []);


    const handleTypeSelect = (e, key) => {
        e.preventDefault();
        let updatedArray = [];
        if (step2.includes(key)) {
            updatedArray = step2.filter((item) => item !== key);
            setStep2(updatedArray);
            setStep2Text(stringStep2(updatedArray))
        } else {
            if (step2.length < 3) {
                updatedArray = [...step2, key];
                setStep2(updatedArray);
                setStep2Text(stringStep2(updatedArray))
            }
        }
    }

    const stringStep2 = (arr) => {
        let r = [];
        arr.map((a) => {
            r.push(tripTypes.travel_types[a].display)
        })

        return r.join(", ");
    }

    const handleRecommendationsRating = (value) => {
        setRating(value)
        const selectedData = step3;
        selectedData.weight = value;
        setStep3(selectedData);
    };

    const getImgUrl = (url) => {
        const urlParts = url.split('/');
        const filename = urlParts[urlParts.length - 1];
        return `https://mytaba-images-prod.s3.amazonaws.com/cluster/${filename}`;
    }

    const submitGetCityTypes = () => {
        const url = `api/v1/location/get-city/${location.country_code},${location.title.toLowerCase()}?attributes=city,display,travel_types`
        request(url, "get", null).then((res) => {
            let travelTypeObjects = res.body[0];
            travelTypeObjects.travelTypeKeys = Object.keys(travelTypeObjects.travel_types)
            setTripTypesArr(travelTypeObjects)
            handleChangeStep(2);
        }).then(() => (scrollToTop())); // wrap the scrollto top in the promise wrapper to be executed after the state is updated
    }

    const getRecommendations = () => {
        let user = props.profile;
        if (user && user.id) {
            const url = `api/v1/session/check-traveler`
            const data = {
                "clerk_id": user.id,
                "create_user_id": ""
            };

            request(url, "post", data).then((res) => {
                createSession(res?.traveler_id)
                setTravelerId(res?.traveler_id)
            }).then(() => (scrollToTop())); // wrap the scrollto top in the promise wrapper to be executed after the state is updated
        } else {
            createSession()
        }

    }

    const createSession = (traveler_id = null) => {
        const url = `api/v1/session/create-session`

        let data = {
            "travel_types": step2.join(","),
            "country": location.country_code,
            "city": location.title.toLowerCase(),
            "create_user_id": ""
        };

        if (traveler_id !== null) {
            data.traveler_id = traveler_id;
        }

        request(url, "post", data).then((res) => {
            setSessionId(res.session_id);
            getRec(res.session_id);
        });
    }

    const getRec = (session_id) => {
        const url = `api/v1/rec/get-rec/${session_id}?popularity=1&quality=1&temp=1.5&rec_num=1`
        setImg(null)
        request(url, "get").then((res) => {
            const response = res.body;
            response.map((data, index) => {
                if (data.img_select && data.img_select.length === 0) {

                    const url = `${DEV_URL}/api/v1/poi/get-image/${data.google_image_ref}`;

                    const options = {
                        method: 'GET',
                        headers: {
                            "Content-Type": "application/json"
                        },
                    };

                    options.headers['api-key'] = APIKEY;

                    fetch(url, options)
                        .then(responseImage => responseImage.arrayBuffer())
                        .then(buffer => {
                            // Convert the ArrayBuffer to a base64-encoded string
                            const base64String = btoa(new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
                            let b = 'data:image/png;base64,' + base64String;
                            data['displayImage'] = b;
                            setImg(b)
                        })
                        .catch(error => console.error("Error fetching image:", error));


                    if (index === 0) {
                        let currentDestinations = data;
                        setCurrentDestinations(currentDestinations);
                    }

                    setStep3Data(response);
                    handleChangeStep(3)

                } else {
                    return `${data.img_prefix}${data.img_select[0]}`;
                }
            });

        });
    }

    const setCurrentDestinations = (currentDestinations) => {
        currentDestinations['weight'] = 0;
        let updatedDestinations = [...step3Destinations, currentDestinations];
        setStep3(currentDestinations);
        setStep3Destinations(updatedDestinations);
        setRating(null);
    }

    const submitRecommendationsRating = () => {
        if (rating) {
            updateSession();
        } else {
            toast.error('Please select any one option', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

    }

    const updateSession = () => {
        const url = `api/v1/session/update-session-profile/${sessionId}`

        let data = {
            "poi_ids": [step3.poi_id],
            "weights": [step3.weight]
        };

        request(url, "put", data).then((res) => {
            if (loopCount === 5) {
                handleChangeStep(4);
            } else {
                setLoopCount(loopCount + 1);
                getRec(sessionId);
            }
        }).then(() => (scrollToTop())); // wrap the scrollto top in the promise wrapper to be executed after the state is updated
    }

    const getFinalRecommendation = (e) => {
        toast('Please wait while your recommendations are being fetched.');
        e.target.disabled = true;
        const url = `api/v1/rec/get-rec/${sessionId}?save=true&temp=0.8&rec_num=6`;
        request(url, "get").then((res) => {
            setRecommendationData(res.body);
            getRequiredPoi();
        }).then(() => (scrollToTop())); // wrap the scrollto top in the promise wrapper to be executed after the state is updated
    }

    const getRequiredPoi = () => {
        const url = `api/v1/poi/get-required/${location.country_code},${location.title.toLowerCase()}`
        request(url, "get").then((res) => {
            setMustSeeSitesData(res.body)
            setShowRecommendation(true);
        });
    }
    const BackButton = ({className, jumpToStep}) => {
        return <>
            <button
                className={`${className} flex items-center px-4 py-2 border mb-6 border-black text-base rounded-full`}
                onClick={() => handleChangeStep(jumpToStep)}>
                <img className='me-2' src={'assets/images/icons/back.png'} alt={'back'}/> Back
            </button>
        </>
    }

    const Heading1 = (props) => {
        return <>
            <p className={`${props?.className} font-mulish text-[36px] font-bold leading-tight`}>
                {props.children}
            </p>
        </>
    }

    const Heading2 = (props) => {
        return <>
            <p className='text-[28px] font-thin leading-tight mb-4'>
                {props.children}
            </p>
        </>
    }

    const CheckBox = () => {
        return <>
            <img className='inline w-[14px]' src={'assets/images/icons/checkmark.png'} alt='checkmark'/>
        </>
    }

    const Quiz1 = (props) => {
        return (
            <>
                <p className="font-mulish font-bold text-black text-[36px]">Where are you traveling?</p>
                <p className="font-thin text-black text-[28px] mb-4">For multiple destinations make a new request after
                    this one!</p>
                {
                    locationsArr && locationsArr.map((l, index1) => {
                        return (
                            <>
                                <div key={index1}>
                                    <p className="font-mulish text-[24px] font-bold mb-4">{l.country}</p>
                                    <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 mb-6'>
                                        {
                                            l.locations.map((loc, index2) => {
                                                let ii = "key-" + index1 + "-" + index2
                                                loc.key = ii;
                                                return (
                                                    <>
                                                        <button
                                                            key={ii}
                                                            className={`relative loc-select--btn ${location?.key === ii ? 'selected' : ''}`}
                                                            onClick={(e) => handleLocSelect(e, loc, l.country, l.value)}
                                                        >
                                                            <div className='quiz--city-box mb-3 text-start'>
                                                                <div
                                                                    className={`${location?.key === ii ? 'selected' : ''} relative quiz--city-image h-[12rem] rounded-2xl`}>
                                                                    <img src={loc.image} alt={loc.title}
                                                                         className='quiz--city-main-image w-full h-full mb-2 me-2 rounded-2xl'
                                                                    />
                                                                    <div
                                                                        className={`${location?.key === ii ? 'flex' : 'hidden'} items-center justify-center absolute w-full h-full top-0 left-0 rounded-2xl bg-[#969696b3]`}>
                                                                        <svg className={`h-[1em] text-white text-[4em]`}
                                                                             aria-hidden="true" focusable="false"
                                                                             data-prefix="fas" data-icon="check" role="img"
                                                                             xmlns="http://www.w3.org/2000/svg"
                                                                             viewBox="0 0 448 512" color="var(--white)">
                                                                            <path fill="currentColor"
                                                                                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <p className="font-mulish transition-all duration-150 text-[24px] font-bold mb-2">{loc.title}</p>
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </>
                        );
                    })
                }

                <button onClick={() => submitGetCityTypes()} disabled={!location} type="button"
                        className="block mx-auto hover:bg-theme--lightBlue hover:text-black font-mulish rounded-full text-white text-[20px] bg-theme--blue font-bold py-2 px-3 mb-4">Submit
                </button>
                <p className="font-thin text-black text-center text-[18px] mb-2">Destination not listed?</p>
                <button type="button"
                        className="block mx-auto bg-theme--lightBlue text-black font-mulish rounded-full text-[20px] hover:bg-theme--blue font-bold py-2 px-4">Request
                    a new destination
                </button>
            </>
        );
    }

    const Quiz2 = (props) => {
        return (
            <>
                <div className='hidden lg:block'>
                    <BackButton jumpToStep={1}/>
                </div>
                <Heading1>What type of trip are you looking for?</Heading1>
                <Heading2>Select up to 3 options below</Heading2>

                <div className='grid grid-cols-2 lg:grid-cols-3 gap-4 mb-8'>
                    {
                        tripTypes && tripTypes?.travelTypeKeys && tripTypes?.travelTypeKeys.map((type, tIndex) => {
                            return (
                                <>
                                    <button onClick={(e) => handleTypeSelect(e, type)} key={tIndex}
                                            className={`p-5 relative rounded-[12px] border-2 ${step2.includes(type) ? 'border-theme--blue' : 'border-transparent'}`}>
                                        <img
                                            className={`${step2.includes(type) ? 'block' : 'hidden'} absolute top-0 left-0 p-2`}
                                            src={'assets/images/icons/checkmark.png'} alt='checkmark'/>
                                        {/* <img className='w-full mb-4' src={tripTypes.travel_types[type].img} alt={tripTypes.travel_types[type].display} /> */}
                                        <img className='w-full mb-4' src={getImgUrl(tripTypes.travel_types[type].img)}
                                             alt={tripTypes.travel_types[type].display}/>
                                        <p className='text-center'>{tripTypes.travel_types[type].display}</p>
                                    </button>
                                </>
                            );
                        })
                    }
                </div>
                <button onClick={(e) => getRecommendations(e)} disabled={step2.length === 0 || step2.length > 3}
                        type="button"
                        className="block mx-auto hover:bg-theme--lightBlue hover:text-black font-mulish rounded-full text-white text-[20px] bg-theme--blue font-bold py-2 px-3 mb-4">Submit
                </button>
            </>
        );
    }

    const Quiz3 = (props) => {
        return (
            <>
                <div className='hidden lg:block'>
                    <BackButton jumpToStep={2}/>
                </div>
                <Heading1>Help us learn more about your trip preferences!</Heading1>
                <Heading2>How interested are you in visiting: </Heading2>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 mb-6'>
                    <div>
                        <img className='w-full' src={img} alt={step3?.poi_name}
                             style={{objectFit: 'cover', height: '100%'}}
                        />
                    </div>
                    <div>
                        <p className='font-mulish text-[24px] font-bold mb-2'>{step3?.poi_name}</p>
                        {/*  <div className="flex flex-wrap items-center mb-4">
                            <span className={`text-sm me-2 px-3 py-1 bg-theme--blue rounded-full text-white`}>{'Tag1'}</span>
                            <span className={`text-sm me-2 px-3 py-1 bg-theme--blue rounded-full text-white`}>{'Tag2'}</span>
                            <span className={`text-sm me-2 px-3 py-1 bg-theme--blue rounded-full text-white`}>{'Tag3'}</span>
                        </div> */}
                        <p className={`text-[16px] mb-2`}>{step3.poi_descrh}</p>
                    </div>
                </div>
                <div className='max-w-[90%] mx-auto grid grid-cols-2 lg:grid-cols-5 gap-4 mb-6'>
                    {
                        recommendationsRating.map(res => {
                            return (<>

                                    <label className='text-[16px] mb-4 text-center' key={res.key}>
                                        <input
                                            className='hidden'
                                            type="radio"
                                            name="customRadio"
                                            value={res.value}
                                            checked={res.value === rating}
                                            onChange={() => handleRecommendationsRating(res.value)}
                                        />
                                        <p className='min-h-[50px]'>{res.key}</p>
                                        <img className='mt-4 mx-auto'
                                             src={`assets/images/${res.value === rating ? "checked" : "unchecked"}.png`}
                                             alt=""
                                        />
                                    </label>
                                </>
                            )
                        })
                    }
                </div>
                <button onClick={() => submitRecommendationsRating()} type="button"
                        className="block mx-auto hover:bg-theme--lightBlue hover:text-black font-mulish rounded-full text-white text-[20px] bg-theme--blue font-bold py-2 px-3 mb-4">Submit
                </button>
            </>
        );
    }

    const Quiz4 = (props) => {
        return (
            <>
                <div className='hidden lg:block'>
                    <BackButton jumpToStep={3}/>
                </div>
                <Heading1 className="text-center mb-6">Your recommendations are ready!</Heading1>
                <button onClick={getFinalRecommendation} disabled={false}
                      className="w-fit block mx-auto hover:bg-theme--lightBlue hover:text-black font-mulish rounded-full text-white text-[20px] bg-theme--blue font-bold py-2 px-6 mb-4 text-center">Explore
                    your trip suggestions now!</button>
            </>
        );
    }

    return (
        <>
            {!showRecommendation && <main className="px-3 lg:px-0 min-h-[500px] py-8 flex items-center" style={{
                background: 'url(assets/images/quiz-bg.png) no-repeat center center',
                backgroundSize: "cover"
            }}>

                <div className="container mx-auto grid grid-cols-3 gap-6">
                    <div className="col-span-3 lg:col-span-1">
                        <Box>
                            <div className="quiz-questions--container">
                                <p className={`${step === 1 ? "block" : "hidden"} lg:block font-mulish text-black text-[24px] font-bold mb-2`}>Get
                                    your recommendations in 3 easy steps</p>

                                <p className={`hidden lg:block font-mulish text-black text-[20px] font-bold mb-3`}>Where
                                    are you going?</p>
                                <p className={`${step === 1 ? "block" : "hidden"} lg:hidden font-mulish text-black text-[16px] font-thin mb-3`}>Step
                                    1: Where are you going?</p>
                                <p className={`${step === 1 ? "block" : "hidden"} lg:block text-[16px] p-2 border rounded-xl mb-3 ${step1 === "" ? (step === 1 ? "text-black border-black bg-[#F3F3F3]" : "text-[#3C3C4399]") : "text-theme--blue border-theme--blue"}`}>{step1 !== "" ? <>
                                    <CheckBox/> {step1}</> : (step === 1 ? "In progress" : "Not Completed")}</p>

                                <p className={`hidden lg:block font-mulish text-black text-[20px] font-bold mb-3`}>What
                                    type of trip are you looking for ?</p>
                                <div
                                    className={`${step === 2 ? "block" : "hidden"} flex flex-wrap items-center lg:hidden mb-5`}>
                                    <BackButton className={`me-2 mb-[0]`} jumpToStep={1}/>
                                    <p className={`${step === 2 ? "block" : "hidden"} lg:hidden font-mulish text-black text-[20px] font-bold mb-0`}>Step
                                        2: Trip Type</p>
                                </div>
                                <p className={`${step === 2 ? "block" : "hidden"} lg:block text-[16px] p-2 border rounded-xl mb-3 ${step2Text === "" ? (step === 2 ? "text-black border-black bg-[#F3F3F3]" : "text-[#3C3C4399]") : "text-theme--blue border-theme--blue"}`}>{step2Text !== "" ? <>
                                    <CheckBox/> {step2Text}</> : (step === 2 ? "In progress" : "Not Completed")}</p>

                                <p className={`hidden lg:block font-mulish text-black text-[20px] font-bold mb-3`}>How
                                    interested are you in the following destinations?</p>
                                <div
                                    className={`${step === 3 ? "block" : "hidden"} flex flex-wrap items-center lg:hidden mb-5`}>
                                    <BackButton className={`me-2 mb-[0]`} jumpToStep={2}/>
                                    <p className={`${step === 3 ? "block" : "hidden"} lg:hidden font-mulish text-black text-[20px] font-bold mb-0`}>Step
                                        3: Ratings</p>
                                </div>
                                {
                                    (step === 1 || step === 2 || step === 3) && step3Destinations.map((data, index) => {
                                        return (
                                            <>
                                                <p className={`hidden lg:block text-[16px] p-2 border rounded-xl mb-2 ${index === step3Destinations.length - 1 ? "text-theme--blue border-theme--blue " : "text-black border-black bg-[#F3F3F3]"}`}>
                                                    <CheckBox/> {data?.poi_name}</p>
                                            </>
                                        )
                                    })
                                }

                                <div
                                    className={`${step === 4 ? "block" : "hidden"} flex flex-wrap items-center lg:hidden`}>
                                    <BackButton className={`me-2 mb-[0]`} jumpToStep={3}/>
                                    <p className={`${step === 4 ? "block" : "hidden"} lg:hidden font-mulish text-black text-[20px] font-bold mb-0`}>Complete!</p>
                                </div>

                            </div>
                        </Box>
                    </div>
                    <div className="col-span-3 lg:col-span-2">
                        <Box>
                            <div className={`${step === 1 ? "block" : "hidden"}`}>
                                <Quiz1/>
                            </div>
                            <div className={`${step === 2 ? "block" : "hidden"}`}>
                                <Quiz2/>
                            </div>
                            <div className={`${step === 3 ? "block" : "hidden"}`}>
                                <Quiz3/>
                            </div>
                            <div className={`${step === 4 ? "block" : "hidden"}`}>
                                <Quiz4/>
                            </div>
                        </Box>
                    </div>
                </div>
            </main>}
            {showRecommendation &&
                <MyRecommendations travelerId={travelerId} sessionId={sessionId} sitesData={mustSeeSitesData}
                                   data={recommendationData}/>}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <ToastContainer/>
        </>
    );
}

export default Quiz;
