import { Link } from "react-router-dom";

const Footer = (props) => {
    
    const currentYear = new Date().getFullYear();

    return (
        <>
            <footer className="bg-theme--lightBlue py-[60px] px-[1em]">
                <div className="container max-w-[550px] mx-auto">
                    <div className="grid text-center grid-cols-1 md:grid-cols-3 gap-4">
                        <div>
                            <p className="font-bold mb-3">Made with ❤ in PIT</p>
                            <p>© {currentYear} myTABA</p>
                        </div>
                        <div>
                            <p className="font-bold mb-3">myTABA</p>
                            <Link to={'https://blog.mytaba.com/contact'}>Contact Us</Link>
                        </div>
                        <div>
                            <p className="font-bold mb-3">Say ‘hi’</p>
                            <div className="flex items-center justify-center">
                                <Link className="p-1" rel="noreferrer" to={'/'} target="_blank">
                                    <img src={'assets/images/icons/facebook.png'} alt="" />
                                </Link>
                                <Link className="p-1" rel="noreferrer" to={'https://www.linkedin.com/company/mytaba'} target="_blank">
                                    <img src={'assets/images/icons/linkedin.png'} alt="" />
                                </Link>
                                <Link className="p-1" rel="noreferrer" to={'https://www.instagram.com/my_taba/'} target="_blank">
                                    <img src={'assets/images/icons/instagram.png'} alt="" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;