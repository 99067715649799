import { Link, Outlet } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../commons/header";
import Footer from "../commons/footer";
import Banner from "../commons/banner-small";
import Box from "../commons/box";

const AccountLayout = (props) => {
    const navigate = useNavigate();
    useEffect(() => {
        checkLogin();
    }, []);
    useEffect(() => {
        checkLogin();
    }, [props]);
    const checkLogin = () => {
        if (props.isLoggedIn) {
            navigate('/dashboard', { replace: true });
        }
    }

    const currentPath = window.location.pathname;

    return (
        <>
            <Header />
            <Banner />

            <main className="my-5">
                <div className="container mx-auto p-3 grid grid-cols-4 gap-4">
                    <div className="col-span-4 lg:col-span-1">
                        <Box>
                            <p className="text-[24px] font-bold mb-1">Profile</p>
                            <Link className={`px-2 py-1 mb-2 block text-[18] font-thin rounded-lg ${currentPath === '/profile' ? 'bg-[#F3F3F3]' : ''}`} to={'/profile'}>Account</Link>
                            <Link className={`px-2 py-1 mb-2 block text-[18] font-thin rounded-lg ${currentPath === '/security' ? 'bg-[#F3F3F3]' : ''}`} to={'/security'}>Security</Link>
                            <p className="text-[24px] font-bold mb-1">Destinations</p>
                            <Link className={`px-2 py-1 mb-2 block text-[18] font-thin rounded-lg ${currentPath === '/saved-searches' ? 'bg-[#F3F3F3]' : ''}`} to={'/saved-searches'}>Saved Searches</Link>
                            <Link className={`px-2 py-1 mb-2 block text-[18] font-thin rounded-lg ${currentPath === '/liked-destinations' ? 'bg-[#F3F3F3]' : ''}`} to={'/liked-destinations'}>Liked Destinations</Link>
                            <p className="text-[24px] font-bold mb-1">Itineraries</p>
                            <Link className={`px-2 py-1 mb-2 block text-[18] font-thin rounded-lg ${currentPath === '/home' ? 'bg-[#F3F3F3]' : ''}`} to={'/home'}>This is a future feature</Link>
                        </Box>
                    </div>
                    <div className="col-span-4 lg:col-span-3">
                        <Box>
                            <Outlet />
                        </Box>
                    </div>
                </div>
            </main>

            <Footer />
        </>
    );
}

export default AccountLayout;