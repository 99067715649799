const Box = (props) => {
    return (
        <>
            <div className="p-5 bg-white rounded-3xl shadow-[0_0px_12px_rgba(0,0,0,0.25)]">
                {props.children}
            </div>
        </>
    );
}

export default Box;