import { useState, useEffect } from "react";
import { GoogleMap, useLoadScript, MarkerF, InfoWindowF } from '@react-google-maps/api';
import { GOOGLE_MAP } from '../../constants/config';
const libraries = ['places'];
const mapContainerStyle = {
    width: '100%',
    height: '100vh',
};
var center = {
    lat: 41.3874, // default latitude
    lng: 2.1686, // default longitude
};
const citiesCordinates = {
    barcelona: {
        lat: 41.3874, // default latitude
        lng: 2.1686
    },
    madrid: {
        lat: 40.4168, // default latitude
        lng: 3.7038
    },
    cordoba: {
        lat: 40.4168, // default latitude
        lng: 3.7038

    },
    granada: {
        lat: 37.1775, // default latitude
        lng: 3.5984
    },
    segovia: {
        lat: 40.9429, // default latitude
        lng: 4.1088
    },
    seville: {
        lat: 37.3891, // default latitude
        lng: 5.9845
    }
}
const Map = (props) => {

    const [recommendationData, setRecommendationData] = useState([]);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [mapCenter, setMapCenter] = useState(null);
    const [markers, setMarkers] = useState([]);

    useEffect(() => {
        setMapCenter(props.data && props.data.length ? citiesCordinates[props.data[0].city.toLowerCase()] : null);
        setRecommendationData(props.data);
        setMarkersPointer(props.data)

    }, [props.data]);

    const setMarkersPointer = (data) => {
        const markers = [];
        data.map((d, i) => {
            if (d.lat_lng) {
                const lat_lng = d.lat_lng.split(',');
                markers.push({
                    name: d.poi_name, latitude: parseFloat(lat_lng[0]), longitude: parseFloat(lat_lng[1])
                })
            }
        })
        setMarkers(markers);
    };

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: GOOGLE_MAP.KEY,
        libraries,
    });

    const handleMarkerClick = (marker) => {
        setSelectedMarker(marker);
    };

    const handleMarkerMouseOver = (marker) => {
        setSelectedMarker(marker);
    };

    const handleMapClick = () => {
        setSelectedMarker(null);
    };

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading maps</div>;
    }

    return (
        <>
            {
                recommendationData && recommendationData.length &&
                <div>
                    <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        zoom={10}
                        center={mapCenter}
                        onClick={handleMapClick}
                    >
                        {
                            markers.length && markers.map((marker, index) => (
                                <MarkerF
                                    position={{ lat: marker.latitude, lng: marker.longitude }}
                                    onClick={() => handleMarkerClick(marker)}
                                // onMouseOver={() => handleMarkerMouseOver(marker)}
                                />
                                // <InfoWindowF
                                //     pixelOffset={"0"}
                                //     position={{ lat: marker.latitude, lng: marker.longitude }}
                                // >
                                //     <p className="markerLabel">{marker.name}</p>
                                // </InfoWindowF>
                            ))
                        }
                        {selectedMarker && (
                            <InfoWindowF
                                position={{ lat: selectedMarker.latitude, lng: selectedMarker.longitude }}
                                onCloseClick={() => setSelectedMarker(null)}
                            >
                                <div>
                                    <h3>{selectedMarker.name}</h3>
                                </div>
                            </InfoWindowF>
                        )}
                    </GoogleMap>
                </div >
            }
        </>
    );
}

export default Map;