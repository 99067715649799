import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../commons/header";
import Footer from "../commons/footer";

const QuizLayout = (props) => {
    /* const navigate = useNavigate();
    useEffect(() => {
        checkLogin();
    }, []);
    useEffect(() => {
        checkLogin();
    }, [props]);

    const checkLogin = () => {
        if (props.isLoggedIn) {
            navigate('/dashboard', { replace: true });
        }
    } */

    return (
        <>
            <Header />
           <Outlet />
            <Footer />
        </>
    );
}

export default QuizLayout;