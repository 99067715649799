import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Menu from "../commons/menu";
import Logo from "../commons/logo";
import { useAuth, SignInButton, SignUpButton, SignOutButton, useUser } from '@clerk/clerk-react';

const Header = (props) => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const { isSignedIn } = useAuth();

    const { user } = useUser();

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        setIsLoggedIn(isSignedIn);
    }, [isSignedIn]);
    return (
        <>
            <header className={`sticky bg-white top-0 z-[100]`}>
                <div className={`${scrolled ? 'py-3' : 'py-3'} container mx-auto px-3`}>
                    <div className="flex items-center justify-between flex-wrap">
                        <div>
                            <Logo />
                        </div>
                        <div className="text-[18px]">
                            <Menu />
                        </div>
                        <div className="hidden lg:flex items-center">
                            {
                                !isLoggedIn && <>

                                    <SignInButton className="hover:bg-theme--lightBlue hover:text-black rounded-full text-white text-[12px] bg-theme--blue font-bold py-2 px-3 me-2" mode="modal" />
                                    <SignUpButton className="hover:bg-theme--lightBlue hover:text-black rounded-full text-white text-[12px] bg-theme--grey font-bold py-2 px-3 me-2" mode="modal" />
                                </>
                            }
                            {
                                isLoggedIn && <>
                                    <SignOutButton className="hover:bg-theme--lightBlue hover:text-black rounded-full text-white text-[12px] bg-theme--grey font-bold py-2 px-3 me-2" mode="modal" />

                                    <Link to={'profile'}>
                                        <img className="rounded-full max-w-[40px]" src={user?.imageUrl} alt={'avatar'} />
                                    </Link>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;