import Heading from "../commons/heading";

const Description = (props) => {
    return (
        <>
            <div className="container mx-auto px-[20px] pt-[120px] pb-[80px]">
                <Heading className={'text-center'}>
                    Skip the endless hours planning your next trip.
                </Heading>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-8">
                    <div className="flex items-start flex-start bg-[#F3F3F3] p-5">
                        <div>
                            <p className="me-4 text-[2rem] lg:text-[2.5rem] text-theme--blue font-black font-mulish">01</p>
                        </div>
                        <div>
                            <p className="text-[1.75rem] lg:text-[2.25rem] font-bold font-mulish mb-2">Plan your trip - In minutes</p>
                            <p className="text-[1.5rem] lg:text-[1.75rem] font-thin mb-1">Take our  quiz and get personalized recommendations - in less than 3 minutes</p>
                        </div>
                    </div>
                    <div className="flex items-start flex-start bg-[#FFFFFF] p-5">
                        <div>
                            <p className="me-4 text-[2rem] lg:text-[2.5rem] text-theme--blue font-black font-mulish">02</p>
                        </div>
                        <div>
                            <p className="text-[1.75rem] lg:text-[2.25rem] font-bold font-mulish mb-2">Discover personalized experiences</p>
                            <p className="text-[1.5rem] lg:text-[1.75rem] font-thin mb-1">Spend less time searching and more time discovering your next great adventure.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Description;