import Heading from "./heading";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import "./newsletter.css";
import {toast, ToastContainer} from "react-toastify";
import {BREVO_KEY} from "../../constants/config";

const Newsletter = (props) => {

    const emailElem = useRef(null);
    const firstNameElem = useRef(null);
    const lastNameElem = useRef(null);
    const [formWidth, setFormWidth] = useState(null);
    const submitHandler = (event) => {
        event.preventDefault();
        const email = emailElem?.current.value;
        const firstName = firstNameElem?.current.value;
        const lastName = lastNameElem?.current.value;

        // validation.
        if (firstName === "") {
            toast.error("First name is required");
            return;
        }
        if (lastName === "") {
            toast.error("Last name is required");
            return;
        }
        if (email === "") {
            toast.error("Email is required");
            return;
        }
        if (email && !validateEmail(email)) {
            toast.error("Invalid email");
            return;
        }

        // if all good, then create a contact of the user.
        const options = {
            method: 'POST',
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                'api-key': BREVO_KEY
            },
            body: JSON.stringify({
                attributes: {FirstName: firstName, LastName: lastName},
                updateEnabled: false,
                email: email
            })
        };

        //need to store the id of the user created.
        let userid;

        fetch('https://api.brevo.com/v3/contacts', options)
            .then((response) => {
                // check the response status. if 400 then user already exists.
                // https://developers.brevo.com/reference/createcontact
                if (response.status === 400) {
                    toast.error('User already exists in database.');
                    throw new Error('User already exists in database.');
                }
                return response.json();
            })
            .then((response) => {
                userid = response.id;

                // now add this user to the mailing list.
                addToMailingList(userid);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => console.log('complete contact flow'));
    }

    function validateEmail(email) {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    }

    function deleteContact(userid) {
        const options = {
            method: 'DELETE',
            headers: {
                accept: 'application/json',
                'api-key': BREVO_KEY
            }
        };

        fetch(`https://api.brevo.com/v3/contacts/${userid}`, options)
            .then(() => {
                console.log(`${userid} deleted`);
            })
            .catch((err) => console.log(err));
    }

    function addToMailingList(userid) {
        const options = {
            method: 'POST',
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                'api-key': BREVO_KEY
            },
            body: JSON.stringify({ids: [userid]})
        };
        const listID = 14; // this is the list id.

        // https://developers.brevo.com/reference/addcontacttolist-1
        // the list id goes here in the url
        fetch(`https://api.brevo.com/v3/contacts/lists/${listID}/contacts/add`, options)
            .then((response) => {
                if (response.status === 400) {
                    throw new Error('User already exists in mailing list.');
                } else if (response.status === 404) {
                    deleteContact(userid);
                    throw new Error('Mailing list not found.');
                    // delete user contact
                }
                return response.json();
            })
            .then((response) => {
                const failedIDs = response.failure;
                if (failedIDs && failedIDs.length > 0) {
                    deleteContact(userid);
                    toast.error('Failed to add user to the mailing list.');
                    throw new Error('Failed to add user to the mailing list.');
                    //delete user contact
                } else {
                    toast.success('Added to the mailing list!');
                    firstNameElem.current.value = "";
                    lastNameElem.current.value = "";
                    emailElem.current.value = "";
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => console.log('complete add to list flow'));
    }

    useEffect(() => {
        const currFNElem = firstNameElem.current;
        const handleResize = () => setFormWidth(firstNameElem.current.offsetWidth * 2);

        window.addEventListener('resize', handleResize);
        return () => firstNameElem.current?.removeEventListener('resize', handleResize);
    }, []);

    // useEffect does not work because the webpage re-calculates box dimensions after render and react forces initial width.
    useLayoutEffect(() => {
        console.log(firstNameElem.current.offsetWidth);
        setFormWidth(firstNameElem.current.offsetWidth * 2);
    }, [firstNameElem]);

    return (

        <>

            <div className="container mx-auto px-[20px] pt-[80px] mb-[120px]">
                <Heading className={'text-[2rem] text-center'}>
                    Be the first!
                </Heading>
                <p className="text-[24px] text-center max-w-[650px] mx-auto leading-1 mt-4 mb-8">Join our list to learn
                    about our latest features, product launches, and updates.</p>

                <div className={`mailing-list ${formWidth ? "max-w-[" + formWidth + 'px]' : ""}`}>
                    <div
                        className="my-1 mx-auto name-fields">
                        <input
                            className="mx-1 flex-1 font-mulish text-center rounded-full mb-2 sm:mb-0 text-[24px] bg-[#F3F3F3] border border-theme--grey font-bold py-2 px-3"
                            name="firstname" type="text" placeholder="First Name" ref={firstNameElem}
                            autoComplete={"on"}/>
                        <input
                            className="mx-1 flex-1 font-mulish text-center rounded-full mb-2 sm:mb-0 text-[24px] bg-[#F3F3F3] border border-theme--grey font-bold py-2 px-3"
                            name="lastname" type="text" placeholder="Last Name" ref={lastNameElem} autoComplete={"on"}/>
                    </div>
                    <div
                        className={`my-1 mx-auto email-field`} style={{width: `${formWidth}px`}}>
                        <input
                            className="flex-1 font-mulish text-center rounded-full mb-2 sm:mb-0 text-[24px] bg-[#F3F3F3] border border-theme--grey font-bold py-2 px-3 mx-2"
                            name="email" type="email" placeholder="Enter your email" ref={emailElem}
                            style={{width: `${formWidth}px`}}
                            autoComplete={"on"}/>
                    </div>
                    <div
                        className={"my-1 mx-auto submit-button"}>
                        <button type="button" onClick={submitHandler} style={{width: `${formWidth / 2}px`}}
                                className="hover:bg-theme--lightBlue hover:text-black flex-1 font-mulish rounded-full text-white text-[24px] bg-theme--blue font-bold py-2 px-3">Sign
                            me up
                        </button>
                    </div>
                </div>

            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    );
}

export default Newsletter;