export const DEV_URL = "https://4yswxuridp2ho6xnxob7wu4icq0klkil.lambda-url.us-east-1.on.aws/";
export const APIKEY = "kjsdhf873thajgdgjs73gjssy8va2pft";

export const BREVO_KEY = "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";

export const COMMON_HEADERS = {
    "Content-Type": "application/json",
};

export const CLERK_KEYS = {
_PUBLISH_KEY: "pk_test_aG90LWFpcmVkYWxlLTkuY2xlcmsuYWNjb3VudHMuZGV2JA",
};

export const FORM_DATA_HEADERS = {
    Accept: "application/json",
};

export const GOOGLE_MAP = {
    KEY: "AIzaSyDcmdkwvzBgsD15LNB72jksp0i9e6mNHo8",
    PLACE_API: "https://maps.googleapis.com/maps/api/place/details/json"
};
