import { Helmet, HelmetProvider } from "react-helmet-async";
import Newsletter from "../commons/newsletter";
import Banner from "../commons/banner";
import Description from "./description";
import Wanderlust from "./wanderlust";
import Explore from "./explore";

const Home = (props) => {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>MyTABA | Home</title>
                    <meta name="description" content="MyTABA | Home" />
                    <meta name="keywords" content="mytaba, travel, trip" />
                </Helmet>
                <main>
                    <Banner />
                    <Description />
                    <Explore />
                    <Wanderlust />
                    <Newsletter />
                </main>
            </HelmetProvider>
        </>
    );
}

export default Home;